<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :show-select="selectable"
          single-select
          dense
          :search="search"
          v-model="selectedRows"
          item-key="_id"
          :sort-by="['createdAt']"
          :sort-desc="['true']"
          @input="updateSelectedFile"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <div class="flex-grow-1"></div>
              <v-text-field
                label="Quick search"
                v-model="search"
                append-icon="search"
                single-line
                hide-details
              />
              <div class="flex-grow-1"></div>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on }">
                  <v-btn dark class="mb-2" v-on="on">Upload new file &uparrow;</v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">Select file for upload</span>
                  </v-card-title>
                  <v-form @submit.prevent="doFileUpload">
                    <v-card-text>
                      <v-file-input
                        label="Select your source file here"
                        clearable
                        show-size
                        chips
                        v-model="selectedFile"
                      />
                    </v-card-text>
                    <v-card-text>
                      <v-textarea
                        label="Your description here (optional)"
                        v-model="fileDescription"
                      />
                    </v-card-text>
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn type="submit" color="primary">Upload</v-btn>
                      <v-btn color="secondary" text @click="dialog = false">Cancel</v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn text small @click="deleteFile(item)">
              <v-icon>mdi-delete</v-icon>
              Delete
            </v-btn>
            <v-btn text
                   small
                   @click="downloadInput({_id: item._id, filename: item.filename})"
            >
              <v-icon>mdi-download</v-icon>
              Download
            </v-btn>
          </template>
        </v-data-table>

      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import downloadFile from '@/components/batch/mixins/downloadFile';

const { mapActions, mapGetters } = createNamespacedHelpers('batch');

export default {
  props: {
    value: Object,
    selectable: Boolean,
  },
  mixins: [downloadFile],
  computed: {
    ...mapGetters({ items: 'inputFileList' }),
  },
  methods: {
    ...mapActions({
      getFiles: 'READ_LIST_inputFile',
      deleteFile: 'DELETE_inputFile',
      uploadFile: 'CREATE_inputFile',
    }),
    async doFileUpload() {
      await this.uploadFile({ file: this.selectedFile, description: this.fileDescription });
      if (this.items.length) {
        this.selectedRows = this.items.slice(-1);
      }
      this.dialog = false;
    },
    updateSelectedFile() {
      if (this.selectedRows && this.selectedRows.length > 0) {
        this.$emit('input', this.selectedRows[0]);
      } else {
        this.$emit('input', null);
      }
    },
  },
  async created() {
    await this.getFiles();
    if (this.items.length > 0) {
      this.selectedRows = [this.items[0]];
    }
  },
  data() {
    return {
      search: '',
      dialog: false,
      selectedFile: null,
      fileDescription: '',
      selectedRows: [],
      headers: [
        {
          text: 'Id',
          value: '_id',
        },
        {
          text: 'File name',
          value: 'filename',
        },

        {
          text: 'Type',
          value: 'contentType',
        },

        {
          text: 'Size',
          value: 'length',
          align: 'end',
        },
        {
          text: 'Uploaded',
          value: 'uploadDate',
        },

        {
          text: 'Description',
          value: 'metadata.description',
        },
        {
          text: 'User',
          value: 'metadata.user.username',
        },
        {
          text: 'Action',
          value: 'action',
        },
      ],
    };
  },
};
</script>
