<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        Batches list
      </v-card-title>
      <v-card-text>
        <BatchesList/>
      </v-card-text>
    </v-card>

  </v-container>
</template>

<script>

import BatchesList from '@/components/batch/BatchesList';

export default {
  components: { BatchesList },
};
</script>

<style scoped>

</style>
