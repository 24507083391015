<template>
  <v-container fluid>
    <v-row align="center" no-gutters>
      <v-col>
        <v-text-field label="Batch name"
                      v-model="name"
                      required
        />
      </v-col>
      <v-col cols="3">
        <v-row justify="end">
          <v-btn text to="/batches" class="ma-2">&leftarrow; Cancel</v-btn>
          <v-btn
            color="primary"
            @click="createOrUpdate"
            :disabled="!name || !selectedFile"
            class="ma-2"
          >
            Next step &rightarrow;
          </v-btn>
        </v-row>
      </v-col>

    </v-row>
    <v-row>
      <v-col>
        <BatchParams v-model="params"/>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title>Select input file or upload new</v-card-title>
          <v-card-text>
            <InputFilesList v-model="selectedFile" selectable/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import InputFilesList from '@/components/batch/InputFilesList';
import BatchParams from '@/components/batch/BatchParams';

const { mapActions } = createNamespacedHelpers('batch');

export default {
  name: 'InitialStep',
  props: ['value'],
  components: {
    BatchParams,
    InputFilesList,
  },
  computed: {
    batch() {
      // eslint-disable-next-line no-underscore-dangle
      return {
        name: this.name,
        params: this.params,
        // eslint-disable-next-line no-underscore-dangle
        inputFile: this.selectedFile._id,
      };
    },
  },
  data() {
    return {
      name: null,
      params: {},
      selectedFile: null,
    };
  },
  methods: {
    ...mapActions({
      newBatch: 'CREATE_batch',
      updateBatch: 'UPDATE_batch',
      loadFile: 'READ_inputFile',
    }),
    async createOrUpdate() {
      let result;
      if (this.value?._id) {
        // eslint-disable-next-line no-underscore-dangle
        result = await this.updateBatch({ id: this.value._id, ...this.batch });
      } else {
        result = await this.newBatch(this.batch);
      }
      this.$emit('input', {
        ...result,
        inputFile: this.selectedFile,
      });
    },
  },
};
</script>
