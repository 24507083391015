<template>
  <v-container fluid>
    <v-row v-if="item.inputFile" align="center" justify="start" class="ma-3">
      <v-btn
        @click="downloadInput({_id: item.inputFile._id, filename: item.inputFile.filename})"
        class="ma-3"
        outlined
      >Download ({{prettyBytes(item.inputFile.length)}})
      </v-btn>
      <div>Input file: {{item.inputFile.filename}}</div>
    </v-row>
    <v-row v-if="item.outputFile" align="center" justify="start" class="ma-3">
      <v-btn
        @click="downloadHandler"
        class="ma-3"
        outlined
      >Download ({{prettyBytes(item.outputFile.length)}})
      </v-btn>
      <div>Output file: {{item.outputFile.filename}}</div>
    </v-row>
  </v-container>
</template>
<script>
import slugify from 'slugify';
import downloadFile from '@/components/batch/mixins/downloadFile';

export default {
  props: ['item'],
  mixins: [downloadFile],
  methods: {
    downloadHandler() {
      this.downloadResult({
        // eslint-disable-next-line no-underscore-dangle
        _id: this.item.outputFile._id,
        filename: slugify(`${this.item.name}-${this.item.outputFile.filename}`),
      });
    },
  },
};
</script>
