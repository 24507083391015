import { saveDownload } from '@/utils/files';
import batchClient from '@/services/batchClient';

const download = (apiCall, id, asFileName) => {
  apiCall.call(batchClient, { id })
    .then((response) => {
      saveDownload(response, asFileName);
    })
    .catch((error) => {
      console.error((error));
    });
};

export default {
  methods: {
    downloadResult({ _id, filename }) {
      download(batchClient.downloadResult, _id, filename);
    },
    downloadInput({ _id, filename }) {
      download(batchClient.downloadFile, _id, filename);
    },
  },
};
