<template>
  <v-container fluid>
    <v-row class="pb-1" v-if="item.status==='NEW'">
      <v-btn text small @click="runAndRefresh(item._id)">
        <v-icon>mdi-play</v-icon>
        Run
      </v-btn>
    </v-row>
    <v-row class="pb-1">
      <v-btn text small @click="batchDelete(item)">
        <v-icon>mdi-delete</v-icon>
        Remove
      </v-btn>
    </v-row>
    <!--              <v-row v-if="item.status ==='NEW'">-->
    <!--                <v-btn text small outlined @click="$router.push('batches/new')">-->
    <!--                  <v-icon>-->
    <!--                    mdi-file-edit-->
    <!--                  </v-icon>-->
    <!--                  Edit-->
    <!--                </v-btn>-->
    <!--              </v-row>-->
    <v-row v-if="item.status==='PROCESSING'">
      <v-btn text small>
        <v-icon>mdi-stop</v-icon>
        Stop
      </v-btn>
    </v-row>
    <v-row class="pb-1" v-if="item.status==='SUCCEED'">
      <v-btn
        text
        small
        @click="downloadHandler"
      >
        <v-icon>mdi-download</v-icon>
        Result
      </v-btn>
    </v-row>
    <v-row v-if="item.status==='SUCCEED' || item.status === 'FAILED'">
      <v-btn
        text
        small
        @click="cloneAndRun(item)"
      >
        <v-icon>mdi-flip-to-front</v-icon>
        Clone & Run
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import slugify from 'slugify';
import downloadFile from '@/components/batch/mixins/downloadFile';

const { mapActions } = createNamespacedHelpers('batch');

export default {
  name: 'BatchActions',
  props: ['item'],
  mixins: [downloadFile],
  methods: {
    ...mapActions({
      batchList: 'READ_LIST_batch',
      batchDelete: 'DELETE_batch',
      batchCreate: 'CREATE_batch',
      batchRead: 'READ_batch',
      createRun: 'CREATE_run',
    }),
    async cloneAndRun(oldBatch) {
      const { params, name, inputFile } = oldBatch;
      const newBatch = await this.batchCreate({ params, name: `based_on_${name}`, inputFile });

      // eslint-disable-next-line no-underscore-dangle
      this.runAndRefresh(newBatch._id);
    },
    downloadHandler() {
      this.downloadResult({
        // eslint-disable-next-line no-underscore-dangle
        _id: this.item.outputFile._id,
        filename: slugify(`${this.item.name}-${this.item.outputFile.filename}`),
      });
    },
    runAndRefresh(id) {
      // todo more smart refresh using vuex state-machine
      this.createRun({ id })
        .finally(() => this.batchList());
    },
  },
};
</script>

<style scoped>

</style>
