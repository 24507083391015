<template>
  <v-card min-width="200px">
    <v-form>
      <v-card-title>Batch parameters</v-card-title>
      <v-card-text>
        <v-radio-group label="Data sources:" row v-model="dataSourceGroup" mandatory>
          <v-radio label="Sanctions only" value="sanctions"/>
          <v-radio label="All" value="all"/>
          <v-radio label="Custom" value="custom"/>
        </v-radio-group>
        <v-autocomplete label="Data sources"
                        multiple dense small-chips deletable-chips
                        :items="dataSourceList"
                        v-model="params.selectedDataSources"
                        @change="dataSourceGroup='custom'"
                        append-icon="mdi-plus"
                        aria-sort="ascending"
        />
        <v-text-field label="Minimum words in name"
                      v-model="params.minimumWordsInName"
                      required
        />
        <v-switch label="Expand initials"
                  v-model="params.expandInitials"
                  required
        />
        <v-switch label="Include blanks for birth date"
                  v-model="params.blanksBirthYears"
                  required
        />
        <v-switch label="Include blanks for country"
                  v-model="params.blanksCountries"
                  required
        />
        <v-slider label="Threshold"
                  v-model="params.threshold"
                  step="0.05"
                  min="0" max="1"
                  thumb-label="always"
                  ticks="always"
                  tick-size="4"
        />
        <v-slider label="ML threshold"
                  v-model="params.mlThreshold"
                  step="0.05"
                  min="0" max="1"
                  thumb-label="always"
                  ticks="always"
                  tick-size="4"
        />
        <!--        <v-textarea label="Query parameters"></v-textarea>-->
      </v-card-text>
    </v-form>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'BatchParams',
  props: ['value'],
  data() {
    return {
      dataSourceGroup: 'sanctions',
      params: {
        selectedDataSources: [],
        minimumWordsInName: 1,
        expandInitials: true,
        blanksBirthYears: true,
        blanksCountries: true,
        // TODO: move to config
        threshold: 0.4,
        mlThreshold: 0.7,
      },
    };
  },
  watch: {
    dataSourceGroup: {
      handler(newVal) {
        if (newVal === 'all') {
          this.params.selectedDataSources = this.dataSourceList.map((e) => e.value);
        } else if (newVal === 'sanctions') {
          this.setDefaultDataSources();
        }
      },
      immediate: true,
    },
    params: {
      handler(newParam) {
        this.updateParams(newParam);
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['dataSourceList']),
  },
  methods: {
    ...mapActions({ getDataSources: 'READ_LIST_dataSource' }),
    updateParams(newParam) {
      this.$emit('input', newParam || this.params);
    },
    setDefaultDataSources() {
      if (this.dataSourceList) {
        this.params.selectedDataSources = this.dataSourceList
          .filter((i) => i.category === 'sanctions')
          .map((e) => e.value);
      }
    },

  },
  created() {
    this.getDataSources();
  },
};
</script>
