<template>
  <v-stepper v-model="stepNum" vertical>
    <v-stepper-step step="1" editable>Start</v-stepper-step>
    <v-stepper-content step="1">
      <InitialStep v-model="batch" @input="stepNum = 2"/>
    </v-stepper-content>

    <v-stepper-step step="2" editable>Run</v-stepper-step>
    <v-stepper-content step="2">
      <PreviewStep v-if="batch"  v-model="batch" @input="stepNum = 3" @back="stepNum--"/>
    </v-stepper-content>

    <v-stepper-step step="3" editable>Finish</v-stepper-step>
    <v-stepper-content step="3">
      <CompletionStep v-if="batch" v-model="batch"/>
    </v-stepper-content>
  </v-stepper>
</template>

<script>
import InitialStep from '@/components/batch/wizard/InitialStep';
import PreviewStep from '@/components/batch/wizard/PreviewStep';
import CompletionStep from '@/components/batch/wizard/CompletionStep';

export default {
  name: 'BatchWizard',
  components: {
    CompletionStep,
    PreviewStep,
    InitialStep,
  },
  data() {
    return {
      stepNum: 1,
      batch: null,
    };
  },
};
</script>
