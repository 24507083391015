<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="itemsWithCheckedStatus"
          item-key="_id"
          show-expand
          fixed-header
          :sort-by="['createdAt']"
          :sort-desc="['true']"
        >
          <template v-slot:top>
            <v-row align="center">
              <v-col cols="6">
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  label="Search"
                  single-line
                  hide-details
                />
              </v-col>
              <v-spacer/>
              <v-col align="end" >
                <v-btn color="primary" @click="newBatch">Create new</v-btn>
              </v-col>
            </v-row>

          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-row align="start" justify="start">
                <v-col cols="auto">
                  <BatchParams v-model="item.params"/>
                </v-col>
                <v-col>
                  <BatchDownloads :item="item"/>
                </v-col>
              </v-row>
            </td>
          </template>
          <template v-slot:item.action="{ item }">
            <BatchActions :item="item"/>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row align="center" justify="center"/>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import moment from 'moment';
import BatchParams from '@/components/common/ParamsTable';
import downloadFile from '@/components/batch/mixins/downloadFile';
import BatchDownloads from '@/components/batch/BatchDownloads';
import BatchActions from '@/components/batch/BatchActions';

const { mapActions, mapGetters } = createNamespacedHelpers('batch');

export default {
  components: {
    BatchActions,
    BatchParams,
    BatchDownloads,
  },
  mixins: [downloadFile],
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Id',
          value: '_id',
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Lines,#',
          value: 'inputFile.metadata.lines',
        },
        {
          text: 'Processed,#',
          value: 'progress',
        },
        {
          text: 'Status',
          value: 'status',

        },
        {
          text: 'Created',
          value: 'createdAt',

        },
        {
          text: 'Created By',
          value: 'user.username',
          divider: true,

        },
        {
          text: 'Actions',
          value: 'action',
          sortable: false,
          align: 'center',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({ items: 'batchList' }),
    itemsWithCheckedStatus() {
      const now = moment();

      return this.items.map((item) => {
        if (item.status !== 'PROCESSING') return item;

        const batchLastUpdateTime = moment(item.updatedAt);

        const datesDifferenceInMinutes = moment
          .duration(now.diff(batchLastUpdateTime))
          .asMinutes();

        return datesDifferenceInMinutes >= 1
          ? {
              ...item,
              status: 'FAILED',
            }
          : item;
      });
    },
  },
  created() {
    this.batchList();
  },
  methods: {
    ...mapActions({
      batchList: 'READ_LIST_batch',
      // batchDelete: 'DELETE_batch',
      // createRun: 'CREATE_run',
    }),
    newBatch() {
      this.$router.push('/batches/new');
    },
  },
};
</script>
