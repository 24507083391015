<template>
  <v-data-table
    :headers="headers"
    :items="items"
    hide-default-footer
    disable-sort
  >
    <template v-slot:item.value="{ item }">
      <v-icon v-if="typeof item.value === 'boolean'">
        {{ item.value ? 'checkbox' : 'cancel' }}
      </v-icon>
      <template v-else>
        {{item.value}}
      </template>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: ['value'],
  computed: {
    items() {
      return Object.entries(this.value)
        .map((pair) => ({
          key: pair[0],
          value: pair[1],
        }));
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Key',
          value: 'key',
        },
        {
          text: 'Value',
          value: 'value',
        },
      ],
    };
  },
};
</script>

<style scoped>

</style>
