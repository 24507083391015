<template>
  <v-container fluid>
    <v-row align="center" no-gutters>
      <v-col>
        <v-progress-linear v-model="progressPercentage" height="25" class="ma-5" :style="{color: this.progressTextColor}">
          <template v-slot="{ value }">
            <strong>{{ Math.ceil(value) }}%</strong>
          </template>
        </v-progress-linear>
      </v-col>
      <v-col>
        <v-row justify="end">
          <v-btn text class="ma-2" @click="$emit('back')"> &leftarrow; Previous</v-btn>
          <v-btn
            color="primary"
            class="ma-2"
            @click="startBatch"
            :disabled="batchInProgress"
          >
            Execute &rightarrow;
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Output format</v-card-title>
          <v-card-text>
            <v-select label="Choose csv format of output file"
                      v-model="selectedOutputFormat"
                      :items="outputFormats">
            </v-select>
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-title>Type of batch</v-card-title>
          <v-card-text>
            <v-select label="Choose batch type"
                      v-model="selectedBatchType"
                      :items="batchTypes"
            />
            <v-tooltip top>
                <v-card-text style="font-size: 1rem"> Format: {{ inputFormats[selectedBatchType].format }} </v-card-text>
                <v-card-text style="font-size: 1rem"> Specs: {{ inputFormats[selectedBatchType].specs }} </v-card-text>
                <v-card-text style="font-size: 1rem"> Example: {{ inputFormats[selectedBatchType].example }} </v-card-text>
              <template v-slot:activator="{ on, attrs }">
                <v-alert type="warning" style="color: black">
                  Please make sure the format of '{{ formattedBatchType }}' batch is correct.
                  Hover over the icon to view the correct format (fields marked with [ ] are optional, but if present - must be in every entry):
                  <v-icon large  v-on="on" v-bind="attrs"> info </v-icon>
                </v-alert>
              </template>
            </v-tooltip>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="value && value.inputFile">
      <v-col cols="5">
        <v-card min-width="250px">
          <v-card-title>Details</v-card-title>
          <v-simple-table>
            <tbody>
            <tr>
              <td>Batch name:</td>
              <td>{{value.name}}</td>
            <tr>
              <td>File:</td>
              <td>{{value.inputFile.filename}}</td>
            </tr>
            <tr>
              <td>Size:</td>
              <td>{{fileSize}}</td>
            </tr>

            </tbody>
          </v-simple-table>
        </v-card>
      </v-col>

      <v-col>
        <v-card min-width="300px">
          <v-card-title>Parameters</v-card-title>
          <BatchParams v-model="value.params"/>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { startCase } from 'lodash';
import prettyBytes from 'pretty-bytes';
import BatchParams from '@/components/common/ParamsTable';

const { mapActions } = createNamespacedHelpers('batch');

export default {
  props: ['value'],
  components: {
    BatchParams,
  },
  data() {
    return {
      batchInProgress: false,
      progressBusy: false,
      progress: 0,
      selectedOutputFormat: 'advanced',
      inputFormats: {
        aml: {
          format: '[id]\t|\tfullName\t|\t[entityType]\t|\t[country]\t|\t[yearOfBirth]',
          specs: 'String\t|\tString\t|\t(i | c)\t|\tString\t|\tInteger',
          example: 'id1234\t|\tkhaled mashal\t|\ti\t|\tsyria\t|\t1956',
        },
        namesCompare: {
          format: 'name1\t|\tname2\t|\tisIndividual',
          specs: 'String\t|\tString\t|\tBoolean',
          example: 'John\t|\tJonathan\t|\ttrue',
        },
      },
      outputFormats: [
        {
          text: 'Simple format',
          value: 'simple',
        },
        {
          text: 'Advanced format',
          value: 'advanced',
        },

        {
          text: 'Mega',
          value: 'mega',
        },
      ],
      selectedBatchType: 'aml',
      batchTypes: [
        {
          text: 'Aml',
          value: 'aml',
        },
        {
          text: 'Names compare',
          value: 'namesCompare',
        },
      ],
    };
  },
  computed: {
    fileSize() {
      return prettyBytes(this.value.inputFile.length);
    },
    progressPercentage() {
      const totalItems = this.value?.inputFile?.metadata?.lines;
      if (totalItems) {
        if (!this.progress) {
          return 0;
        }
        return (this.progress / totalItems) * 100;
      }
      return 0;
    },
    progressTextColor() {
      return this.progressPercentage < 50 ? 'black' : 'white';
    },
    formattedBatchType() {
      return startCase(this.selectedBatchType);
    },
  },
  methods: {
    ...mapActions({
      runBatch: 'CREATE_run',
    }),
    async startBatch() {
      this.batchInProgress = true;
      const handler = setInterval(this.updateProgress, 500);

      let result;
      try {
        if (this.selectedBatchType === 'aml') {
          result = await this.startAmlBatch();
        } else {
          result = await this.startNamesCompareBatch();
        }
        this.$emit('input', result);
      } catch (e) {
        console.error(e);
      } finally {
        clearInterval(handler);
        this.batchInProgress = false;
      }
    },
    async startAmlBatch() {
        return this.runBatch({
          id: this.value._id,
          type: 'aml',
          params: this.selectedOutputFormat,
        });
    },
    async startNamesCompareBatch() {
      return this.runBatch({
        id: this.value._id,
        type: 'namesCompare',
      });
    },
    async updateProgress() {
      if (this.progressBusy) {
        return;
      }
      this.progressBusy = true;
      try {
        // eslint-disable-next-line no-underscore-dangle
        const response = await this.$batchClient.getProgress({ id: this.value._id });
        this.progress = response.data.progress;
      } catch (e) {
        console.error(e);
      } finally {
        this.progressBusy = false;
      }
    },
  },
};
</script>
