<template>
  <v-card>
    <v-card-text>
      <v-alert text outlined type="success" v-if="value.status==='SUCCEED'">
        Batch "{{value.name}}" run completed successfully.
      </v-alert>
      <v-alert text type="error" v-else>
        Batch "{{value.name}}" run failed.
      </v-alert>
    </v-card-text>
    <v-card-actions>
      <v-btn
        text
        to="/batches"
      >
        &leftarrow;Return to list of batches
      </v-btn>
      <v-btn
        color="primary"
        @click="download"
        v-if="value.status==='SUCCEED'"
      >
        Download results &downarrow;
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>

import slugify from 'slugify';
import downloadFile from '@/components/batch/mixins/downloadFile';

export default {
  name: 'CompletionStep',
  mixins: [downloadFile],
  props: ['value'],
  methods: {
    download() {
      this.downloadResult({
        // eslint-disable-next-line no-underscore-dangle
        _id: this.value.outputFile._id,
        filename: slugify(`${this.value.name}-${this.value.outputFile.filename}`),
      });
    },
  },
};
</script>
