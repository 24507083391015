<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        Input files
      </v-card-title>
      <v-card-text>
        <InputFilesList/>
      </v-card-text>
    </v-card>

  </v-container>

</template>

<script>

import InputFilesList from '@/components/batch/InputFilesList';

export default {
  name: 'InputFiles',
  components: { InputFilesList },
};
</script>

<style scoped>

</style>
